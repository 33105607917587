// ./utils/get-stripejs.ts
import { env } from "@env/client.mjs";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export default getStripe;
