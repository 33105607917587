import localFont from "next/font/local";
import { Elements } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import type { NextPage } from "next";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import type { AppType } from "next/app";
import Head from "next/head";
import type { ReactElement, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Chain,
  configureChains,
  Connector,
  createClient,
  WagmiConfig,
} from "wagmi";
import { hardhat, polygonMumbai } from "wagmi/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { ToastContainer } from "react-toastify";
// export { reportWebVitals } from "next-axiom";

import Web3AuthConnector from "@components/web3/Web3AuthConnector";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { env } from "@env/client.mjs";
import { ServerContextProvider } from "@hooks/isServer";
import { PageProvider } from "@providers/page";
import { trpc } from "@utils/trpc";

import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";
import "@styles/globals.css";
import "glider-js/glider.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import getStripe from "@server/services/stripeClient";

dayjs.extend(relativeTime);
dayjs.extend(duration);

const calSans = localFont({
  src: [
    {
      path: "../../public/fonts/CalSans-SemiBold.woff",
    },
    {
      path: "../../public/fonts/CalSans-SemiBold.otf",
    },
  ],
  weight: "600",
  style: "normal",
  display: "swap",
  variable: "--font-cal-sans",
});
const dmSans = localFont({
  src: [
    {
      path: "../../public/fonts/DM_Sans/DMSans-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/DM_Sans/DMSans-Italic.ttf",
      weight: "400",
      style: "italic",
    },
    {
      path: "../../public/fonts/DM_Sans/DMSans-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/DM_Sans/DMSans-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/DM_Sans/DMSans-BoldItalic.ttf",
      weight: "700",
      style: "italic",
    },
  ],
  display: "swap",
  variable: "--font-dm-sans",
});

let applicationChain: Chain;
switch (env.NEXT_PUBLIC_BLOCKCHAIN_ENV) {
  // applicationChain = chain.polygon;
  // break;
  case "test":
    applicationChain = hardhat;
    break;
  case "production":
  case "development":
  case "staging":
  default:
    applicationChain = polygonMumbai;
}

const { chains, provider } = configureChains(
  [applicationChain],
  [
    infuraProvider({ apiKey: env.NEXT_PUBLIC_INFURA_PROJECT_ID }),
    publicProvider(),
  ]
);

const client = createClient({
  autoConnect: true,
  connectors: () =>
    (typeof window !== "undefined"
      ? [
          new InjectedConnector({ chains }),
          new WalletConnectConnector({
            options: {
              qrcode: true,
            },
            chains,
          }),
          Web3AuthConnector(chains),
        ]
      : []) as Connector[],
  provider,
  // webSocketProvider,
});
const queryClient = new QueryClient();

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title>Spaceship Marketplace</title>
      </Head>
      <ErrorBoundary>
        <ServerContextProvider>
          <WagmiConfig client={client}>
            <SessionProvider session={session}>
              <Elements stripe={getStripe()}>
                <PageProvider>
                  <QueryClientProvider client={queryClient}>
                    {getLayout(
                      <>
                        <div
                          className={`${dmSans.variable} ${calSans.variable} h-full dark:subpixel-antialiased`}
                        >
                          <Component {...pageProps} />
                        </div>
                        <ToastContainer autoClose={3000} theme="colored" />
                      </>
                    )}
                  </QueryClientProvider>
                </PageProvider>
              </Elements>
            </SessionProvider>
          </WagmiConfig>
        </ServerContextProvider>
      </ErrorBoundary>
    </>
  );
};

export default trpc.withTRPC(MyApp);
