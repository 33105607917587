// Need to have this import and the next-transpile-modules config for this package in order to pass build
import { env } from "@env/client.mjs";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { TorusWalletConnectorPlugin } from "@web3auth/torus-wallet-connector-plugin";
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector/dist/web3authWagmiConnector.esm";
import { Chain, Connector } from "wagmi";

export default function Web3AuthConnectorInstance(chains: Chain[]) {
  const [chain] = chains;
  if (!chain) throw new Error("No chains");
  // Create Web3Auth Instance
  const name = "Spaceship Foundation";
  const web3AuthInstance = new Web3Auth({
    clientId: env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID,
    chainConfig: {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: "0x" + chain.id.toString(16),
      rpcTarget: chain.rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
      displayName: chain.name,
      tickerName: chain.nativeCurrency?.name,
      ticker: chain.nativeCurrency?.symbol,
    },
    uiConfig: {
      appName: name,
      theme: "dark",
      loginMethodsOrder: [
        // "email_passwordless",
        "google",
        "facebook",
        "discord",
        "github",
      ],
      defaultLanguage: "en",
      appLogo:
        "https://marketplace-base.vercel.app/img/spaceship_icon_white.png", // Your App Logo Here
      modalZIndex: "2147483647",
    },
  });

  // // Add openlogin adapter for customisations
  const openloginAdapterInstance = new OpenloginAdapter({
    adapterSettings: {
      network: env.NEXT_PUBLIC_WEB3AUTH_NETWORK,
      uxMode: "popup",
      whiteLabel: {
        dark: true,
        name,
        theme: { colors: "#E35600" },
        logoDark: "/img/spaceship_icon.png",
        logoLight: "/img/spaceship_icon_white.png",
      },
    },
  });
  web3AuthInstance.configureAdapter(openloginAdapterInstance);

  // Add Torus Wallet Plugin (optional)
  const torusPlugin = new TorusWalletConnectorPlugin({
    torusWalletOpts: {
      buttonPosition: "bottom-left",
    },
    walletInitOptions: {
      whiteLabel: {
        theme: {
          isDark: true,
          colors: {
            torusBrand1: "#E35600",
          },
        },
        logoDark: "https://marketplace-base.vercel.app/img/spaceship_icon.png",
        logoLight:
          "https://marketplace-base.vercel.app/img/spaceship_icon_white.png",
        topupHide: true,
        featuredBillboardHide: true,
        disclaimerHide: true,
      },
      useWalletConnect: true,
      // enableLogging: true,
    },
  });
  web3AuthInstance.addPlugin(torusPlugin);

  const connector = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
    },
  });
  return connector;
}

export const isWeb3AuthConnector = (
  connector: Connector
): connector is Web3AuthConnector => {
  return connector.id === "web3auth";
};
